<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Utilizatori</h2>
      <b-button-toolbar class="pb-3">
        <b-button class="p-2" id="addUserToolbarButton" @click="showAddUserModal" variant="primary" v-b-tooltip.hover>
          <i class="fas fa-plus-square" />
          <span class="d-none d-md-inline ml-1">Utilizator nou</span>
        </b-button>
        <b-tooltip target="addUserToolbarButton" placement="topleft" custom-class="d-md-none">Utilizator nou</b-tooltip>
      </b-button-toolbar>
    </div>
    <b-table
      :show-empty="!tableBusy"
      small
      striped
      hover
      empty-text="Nu este configurat niciun utilizator"
      :busy.sync="tableBusy"
      :items="items"
      :fields="tableFields">
      <template v-slot:cell(isActive)="row">
        <b-badge variant="primary" v-if="row.value">Activ</b-badge>
        <b-badge variant="secondary" v-else>Inactiv</b-badge>
      </template>
      <template v-slot:cell(roles)="row">
        <span v-for="userRole of row.value" v-bind:key="userRole">
          <b-badge>{{userRole}}</b-badge>&nbsp;
        </span>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button v-b-tooltip.hover title="Detalii utilizator" size="sm" @click="showEditUserModal(row.item)">
         <i class="fas fa-edit" />
        </b-button>
        <b-button v-b-tooltip.hover title="Schimbă parola" size="sm" @click="showChangePasswordModal(row.item)">
         <i class="fas fa-key" />
        </b-button>
      </template>
    </b-table>

    <b-modal id="user-properties-modal"
      :title="userPropertiesModalTitle"
      size="lg"
      height="auto"
      scrollable
      @ok="handleUserPropertiesModal">
      <div>
        <b-form-group label="Username">
          <b-form-input v-model="modalUser.username" :disabled="!editingNewUser"></b-form-input>
        </b-form-group>
        <b-form-group label="E-mail">
          <b-form-input v-model="modalUser.emailAddress"></b-form-input>
        </b-form-group>
        <b-form-group label="Nume complet">
          <b-form-input v-model="modalUser.fullName"></b-form-input>
        </b-form-group>
        <b-form-group label="Stare">
          <multiselect
            v-model="stateSelectedOption"
            placeholder="Stare"
            selected-label="Selectat"
            select-label="Alege cu Enter"
            deselect-label="Elimină cu Enter"
            :options="stateOptions"
            :disabled="editingCurrentUser"
            :allow-empty="true"
            :multiple="false"
            label="label"
            track-by="label"
            class="multiselect-modal"
          >
          </multiselect>
        </b-form-group>
        <b-form-group label="Parola" v-if="editingNewUser">
          <b-form-input type="password" v-model="password"></b-form-input>
        </b-form-group>
        <b-form-group label="Repetă parola" v-if="editingNewUser">
          <b-form-input type="password" v-model="repeatPassword"></b-form-input>
        </b-form-group>
        <b-form-group label="Profil facturare" v-if="!editingNewUser">
          <b-form-input v-model="modalUser.usernameOperatorFacturare"></b-form-input>
        </b-form-group>
        <b-form-group label="Cod agent vânzari" v-if="!editingNewUser">
          <b-form-input v-model="modalUser.codAgentVanzari"></b-form-input>
        </b-form-group>
        <b-form-group label="Roluri asociate">
          <multiselect
            v-model="modalUser.roles"
            selected-label="Selectat"
            select-label="Alege cu Enter"
            deselect-label="Elimină cu Enter"
            :options="roles"
            :disabled="editingCurrentUser"
            :allow-empty="true"
            :multiple="true"
            class="multiselect-modal"
          >
          </multiselect>
        </b-form-group>
      </div>
    </b-modal>

    <b-modal id="user-change-password-modal"
      title="Schimbare parola"
      size="lg"
      scrollable
      @ok="handleUserChangePasswordModal">
      <div>
        <b-form-group label="Username">
          <b-form-input v-model="modalUser.username" disabled></b-form-input>
        </b-form-group>
        <b-form-group label="Parola">
          <b-form-input type="password" v-model="password"></b-form-input>
        </b-form-group>
        <b-form-group label="Repetă parola">
          <b-form-input type="password" v-model="repeatPassword"></b-form-input>
        </b-form-group>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { WebAppRole } from '../constants';

export default {
  name: 'AdminUsers',
  data() {
    return {
      tableFields: [
        {
          key: 'username',
          label: 'Utilizator',
        },
        {
          key: 'isActive',
          label: 'Stare',
        },
        {
          key: 'fullName',
          label: 'Nume complet',
        },
        {
          key: 'emailAddress',
          label: 'Adresă email',
        },
        {
          key: 'usernameOperatorFacturare',
          label: 'Profil facturare',
        },
        {
          key: 'codAgentVanzari',
          label: 'Cod agent vânzări',
        },
        {
          key: 'roles',
          label: 'Roluri',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      items: [],
      roles: [
        WebAppRole.Administrator,
        WebAppRole.Storekeeper,
        WebAppRole.SalesAgent,
        WebAppRole.InvoiceIssuer,
        WebAppRole.Procurement,
      ],
      stateOptions: [
        { value: true, label: 'Activ' },
        { value: false, label: 'Inactiv' },
      ],
      stateSelectedOption: null,
      tableBusy: false,
      modalUser: {},
      password: '',
      repeatPassword: '',
    };
  },
  computed: {
    ...mapState(['xhrRequestRunning', 'username']),
    userPropertiesModalTitle() {
      return this.editingNewUser ? 'Adăugare utilizator' : 'Detalii utilizator';
    },
    editingNewUser() {
      return this.modalUser && !this.modalUser.webAppUserGuid;
    },
    editingCurrentUser() {
      return this.modalUser && this.modalUser.username === this.username;
    },
  },
  async mounted() {
    await this.loadInitialData();
  },
  methods: {
    ...mapActions(['performPlatformUsersList', 'performAddPlatformUser', 'performEditPlatformUser', 'performChangePlatformUserPassword']),
    async loadInitialData() {
      this.items = await this.performPlatformUsersList();
    },
    showEditUserModal(user) {
      this.modalUser = { ...user };
      this.stateSelectedOption = this.stateOptions.find((x) => user.isActive === x.value);
      this.modalUser.roles = user.roles.slice();

      this.$bvModal.show('user-properties-modal');
    },
    showChangePasswordModal(user) {
      this.modalUser = { ...user };
      this.modalUser.roles = user.roles.slice();

      this.password = '';
      this.repeatPassword = '';

      this.$bvModal.show('user-change-password-modal');
    },
    showAddUserModal() {
      this.modalUser = {
        roles: [],
        isActive: this.stateOptions.find((x) => x.value),
        webAppUserGuid: null,
        username: null,
        fullName: null,
        emailAddress: null,
      };

      this.password = '';
      this.repeatPassword = '';

      this.$bvModal.show('user-properties-modal');
    },
    async handleUserPropertiesModal(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      if (!this.modalUser.webAppUserGuid) {
        // check password
        if (this.password !== this.repeatPassword) {
          return;
        }

        if (!this.password) {
          return;
        }

        if (!this.modalUser.username) {
          return;
        }
      }

      if (!this.modalUser.emailAddress) {
        return;
      }

      if (!this.modalUser.fullName) {
        return;
      }

      if (!this.modalUser.webAppUserGuid) {
        await this.performAddPlatformUser({
          ...this.modalUser,
          password: this.password,
          // These don't need to be sent
          webAppUserGuid: undefined,
          repeatPassword: undefined,
          // Need to convert from object to object's value as we're using a vue-multiselect
          isActive: this.stateSelectedOption.value,
        });
      } else {
        await this.performEditPlatformUser({
          ...this.modalUser,
          // Need to convert from object to object's value as we're using a vue-multiselect
          isActive: this.stateSelectedOption.value,
        });
      }

      await this.loadInitialData();

      this.$nextTick(() => this.$bvModal.hide('user-properties-modal'));
    },
    async handleUserChangePasswordModal(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      if (this.password !== this.repeatPassword) {
        return;
      }

      if (!this.password) {
        return;
      }

      await this.performChangePlatformUserPassword({
        webAppUserGuid: this.modalUser.webAppUserGuid,
        password: this.password,
        repeatPassword: this.repeatPassword,
      });

      await this.loadInitialData();

      this.$nextTick(() => this.$bvModal.hide('user-change-password-modal'));
    },
  },
};
</script>
